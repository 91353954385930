import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./vee-validate";
import VueCookies from 'vue-cookies'
import VueConfetti from 'vue-confetti';
import VueGtag from 'vue-gtag';

/* Data Table */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

/* Chart JS */
import "chart.js/dist/chart.js";

/* jsZip */
import jszip from 'jszip';

/* Data Table */
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faGauge, faBell, faBuilding, faUser } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';

/* add icons to the library */
library.add(faGauge, faBell, faBuilding, faUser)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueCookies, { expire: '30min' });
Vue.use(VueConfetti);
Vue.use(jszip);
Vue.use(VueGtag, {
  config: { id: 'G-8D0Y3HH9XX' }
})

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
