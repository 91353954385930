import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";
import $ from "jquery";

Vue.use(VueRouter)

function checkMaster(to, from, next) {
  let url = process.env.VUE_APP_API_DEV ? `${process.env.VUE_APP_API_DEV}roleAuth` : `${process.env.VUE_APP_API_PRO}roleAuth`;
  axios
    .get(url, {
      headers: {
        token: $cookies.get("token"),
      }
    })
    .then((response) => {
      if (response.data.response != 3) {
        if (response.data.response == 1) {
          next();
        } else {
          next({ name: "home" });
        }
      } else {
        next({ name: "Acceso" });
      }
    });
}

function checkAuth(to, from, next) 
{
  let url = process.env.VUE_APP_API_DEV ? `${process.env.VUE_APP_API_DEV}roleAuth` : `${process.env.VUE_APP_API_PRO}roleAuth`;
  axios
    .get(url, {
      headers: {
        token: $cookies.get("token"),
      }
    })
    .then((response) => {
      if (response.data.response == 3) {
        next({ name: "Acceso" });
      } else {
        next();
      }
    });
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/Ruleta/:id',
    name: 'Ruleta',
    component: () => import('../views/RouletteView.vue')
  },
  {
    path: '/Campanas',
    name: 'Campaigns',
    component: () => import('../views/CampaignsView.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/Empresas',
    name: 'Companies',
    component: () => import('../views/CompaniesView.vue'),
    beforeEnter: checkMaster
  },
  {
    path: '/Usuarios',
    name: 'Users',
    component: () => import('../views/UsersView.vue'),
    beforeEnter: checkMaster
  },
  {
    path: '/Acceso',
    name: 'Acceso',
    component: () => import('../views/LoginView.vue'),
  },
]

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes
})

export default router
